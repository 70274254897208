import { Route } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';

export const appRoutes: Route[] = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () =>
                    import(
                        'app/modules/funnel/components/basic-funnel/basic-funnel.routes'
                    ),
            },
            {
                path: 'biz/:id',
                loadChildren: () =>
                    import(
                        'app/modules/funnel/components/business-funnel/business-funnel.routes'
                    ),
            },
        ],
    },
];
